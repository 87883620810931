import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AUTH_MODAL_PAGES, SUBSCRIBE_MODAL_PAGES } from '../constants/enums';

export interface Modals {
  authModalOpen: boolean;
  authModalPage: AUTH_MODAL_PAGES;
  subscribeModalOpen: boolean;
  subscribeModalPage: SUBSCRIBE_MODAL_PAGES;
  cookieModalOpen: boolean;
  createEventModalOpen: boolean;
  profileModalOpen: boolean;
  videoModalOpen: boolean;
}

export const initialState: Modals = {
  authModalOpen: false,
  authModalPage: AUTH_MODAL_PAGES.SIGN_UP,
  subscribeModalOpen: false,
  subscribeModalPage: SUBSCRIBE_MODAL_PAGES.DETAILS,
  cookieModalOpen: false,
  createEventModalOpen: false,
  profileModalOpen: false,
  videoModalOpen: false,
};

const slice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    clearModalsSlice() {
      return initialState;
    },
    openAuthModal(state, action: PayloadAction<AUTH_MODAL_PAGES>) {
      if (action.payload) {
        // Set starting page for auth modal
        state.authModalPage = action.payload;
      }
      state.authModalOpen = true;
      state.subscribeModalOpen = false;
    },
    setAuthModalPage(state, action: PayloadAction<AUTH_MODAL_PAGES>) {
      state.authModalPage = action.payload;
    },
    openSubscribeModal(state, action: PayloadAction<SUBSCRIBE_MODAL_PAGES>) {
      if (action.payload) {
        // Set starting page for subscribe modal
        state.subscribeModalPage = action.payload;
      }
      state.subscribeModalOpen = true;
      state.authModalOpen = false;
    },
    setSubscribeModalPage(state, action: PayloadAction<SUBSCRIBE_MODAL_PAGES>) {
      state.subscribeModalPage = action.payload;
    },
    openCookieModal(state) {
      state.cookieModalOpen = true;
    },
    openCreateEventModal(state) {
      state.createEventModalOpen = true;
    },
    openProfileModal(state) {
      state.profileModalOpen = true;
    },
    openVideoModal(state) {
      state.videoModalOpen = true;
    },
  },
});

const { actions, reducer } = slice;
export const {
  clearModalsSlice,
  openAuthModal,
  setAuthModalPage,
  openSubscribeModal,
  setSubscribeModalPage,
  openCookieModal,
  openCreateEventModal,
  openProfileModal,
  openVideoModal,
} = actions;
export default reducer;
