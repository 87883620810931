export class ApiErrorResponse extends Error {
  code: string;

  constructor(
    code: string = 'internalServerError',
    message: string = 'This is an internal error, check logs for details.',
  ) {
    super();
    this.code = code;
    this.message = message;
  }
}

export class MethodNotAllowedError extends ApiErrorResponse {
  constructor(method?: string) {
    super('methodNotAllowed', `Method ${method} not allowed`);
  }
}

export class ApiValidationError extends ApiErrorResponse {
  key?: string;
  constructor(message: string, key?: string) {
    super('validationError', message);
    this.key = key;
  }
}

export class MailchimpError extends ApiErrorResponse {
  constructor() {
    super('mailchimpError', 'An error was thrown by Mailchimp, check logs for more details.');
  }
}

export class CmsError extends ApiErrorResponse {
  constructor() {
    super(
      'cmsError',
      'There was an error retrieving data from the CMS, check logs for more details.',
    );
  }
}

export class VimeoError extends ApiErrorResponse {
  constructor() {
    super(
      'vimeoError',
      'There was an error retrieving videos from vimeo, check logs for more details.',
    );
  }
}

export class EventbriteError extends ApiErrorResponse {
  constructor() {
    super(
      'eventbriteError',
      'There was an error retrieving events from Eventbrite, check logs for more details.',
    );
  }
}

export class DoesNotExistError extends ApiErrorResponse {
  constructor(objectType: string) {
    super(`${objectType}DoesNotExist`, `${objectType} with that id does not exist.`);
  }
}

export class NoClassesError extends ApiErrorResponse {
  constructor(radius: number) {
    super(
      'noClassesError',
      `There are no classes within a ${radius}km radius of the given location.`,
    );
  }
}

export class AuthenticationError extends ApiErrorResponse {
  constructor(message: string) {
    super('authenticationError', message);
  }
}

export class PaymentError extends ApiErrorResponse {
  constructor(message: string) {
    super('paymentError', message);
  }
}
