export enum ENVIRONMENTS {
  LOCAL = 'local',
  PREVIEW = 'preview',
  PRODUCTION = 'production',
}

export enum ROUTINE_POSITION {
  PREVIOUS = 'previous',
  CURRENT = 'current',
  NEXT = 'next',
}

export enum VIDEO_CATEGORY {
  YOGA = 'yoga',
  STORIES = 'stories',
  LIFE_SKILLS = 'life_skills',
  MINDSET = 'mindset',
  QA = 'qa',
}

export enum SOCIAL_MEDIA {
  FACEBOOK = 'Facebook',
  INSTAGRAM = 'Instagram',
  TIKTOK = 'Tiktok',
  YOUTUBE = 'Youtube',
}

// The following enums are used in Storyblok datasources, changes must also be copied there

export enum COLOURS {
  BLACK = 'black',
  WHITE = 'white',
  GRAY = 'gray.900',
  PINK = 'pink.500',
}

export enum HORIZONTAL_ALIGNMENTS {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum VERTICAL_ALIGNMENTS {
  TOP = 'top',
  CENTER = 'center',
  BOTTOM = 'bottom',
}

export enum SIZES {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export enum EXTENDED_SIZES {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export enum IMAGE_POSITION {
  BACKGROUND = 'background',
  INLINE = 'inline',
}

export enum BUTTON_VARIANTS {
  SOLID = 'solid',
  OUTLINE = 'outline',
  GHOST = 'ghost',
  UNSTYLED = 'unstyled',
  LINK = 'link',
}

export enum SPOTLIGHT {
  LEFT_1 = 'left_1',
  LEFT_2 = 'left_2',
  CENTER_1 = 'center_1',
  CENTER_2 = 'center_2',
  RIGHT_1 = 'right_1',
  RIGHT_2 = 'right_2',
  STRONG = 'strong',
}

export enum OVERLAYS {
  FULL = 'linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 100%)',
  FULL_LIGHT = 'linear-gradient(0deg, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0.4) 100%)',
  FULL_DARK = 'linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.6) 100%)',
  LEFT = 'linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
  LEFT_LIGHT = 'linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  LEFT_DARK = 'linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  RIGHT = 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
  RIGHT_LIGHT = 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  RIGHT_DARK = 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  TOP = 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
  TOP_LIGHT = 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  TOP_DARK = 'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
  BOTTOM = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)',
  BOTTOM_LIGHT = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)',
  BOTTOM_DARK = 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%)',
}

export enum AUTH_MODAL_PAGES {
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  SIGN_UP_PROFILE = 'sign_up_profile',
  SIGN_UP_SUCCESS = 'sign_up_success',
}

export enum SUBSCRIBE_MODAL_PAGES {
  DETAILS = 'details',
  SUCCESS = 'success',
}

export enum EVENT_TYPE {
  CLASS = 'class',
  WORKSHOP = 'workshop',
}

export enum LICENSE_TYPE {
  COMMERCIAL = 'commercial',
  HEELS = 'heels',
}
