import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { Action } from 'redux';
import { api } from './api';
import classesReducer from './classesSlice';
import cookiesReducer from './cookiesSlice';
import eventsReducer from './eventsSlice';
import modalsReducer from './modalsSlice';
import routinesReducer from './routinesSlice';
import userReducer from './userSlice';
import videoModalReducer from './videoSlice';
import videosReducer from './videosSlice';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    modals: modalsReducer,
    classes: classesReducer,
    events: eventsReducer,
    routines: routinesReducer,
    videos: videosReducer,
    cookiesState: cookiesReducer,
    videoModal: videoModalReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

const initStore = () => store;

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof initStore>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;
export type AppDispatch = ReturnType<AppStore['dispatch']>;

export const wrapper = createWrapper<AppStore>(initStore);
export default store;
